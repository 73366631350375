.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin
    1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);
    }
}

/* Reset styles */
html, body, p, ol, ul, li, dl, dt, dd, h1, h2, h3, h4, h5, h6, fieldset, legend, input, textarea, select, button {
    margin: 0;
    padding: 0;
}

/* Global styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.5;
    background-color: #222222;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    text-align: center;
}

/* Loader styles */
.loader {
    margin-bottom: 20px;
}

/* Ensure each element is on its own line */
.container {
    width: 80%;
    max-width: 800px;
}

.container > * {
    margin-bottom: 20px;
}

img {
    max-width: 500px;
}

* {
    margin: 10px;
}
